import {ApptSlot} from "@services/monolith/availability";
import moment from "moment-timezone";
import {useRouter} from "next/router";
import {useEffect, useState} from "react";
import {selectSelectedRegion} from "src/store/slices/userLocation";

import {loadAvailableSlots} from "../_services/api";
import {FeatureFlag} from "../_services/featureFlagConstants";
import {ApptSlotBlock} from "../_services/types";
import {laTimezone, millisPerMinute} from "../components/_common/_constants";
import {PracticeId} from "../constants/practiceIds";
import {SpecialtyId} from "../constants/specialtyIds";
import {useTypedSelector} from "../store";
import {RootStateLocation} from "../store/types";
import {fetchCachedSlotAround} from "../utils/fetchCachedSlotAround";
import {useFeatureFlag} from "./useFeatureFlags";

export const getSoonestSlotFromApptSlotBlock = (slotTimes: ApptSlotBlock[]): number =>
  slotTimes?.[0]?.[0]?.time;

export const useGetSoonestInPracticeBySpecialtyId = ({
  specialtyId,
  practiceId = PracticeId.CARBON,
  daysCount = 7,
}: {
  specialtyId: SpecialtyId;
  practiceId?: string;
  daysCount?: number;
}): Partial<ApptSlot | null> => {
  const {pageLoaded, practices, locations} = useTypedSelector(({config}) => config);

  const [slot, setSlot] = useState<Partial<ApptSlot>>({});

  const enableCache = useFeatureFlag<boolean>(FeatureFlag.GROWTH_CACHED_SLOTS_ENABLED);

  useEffect(() => {
    if (!pageLoaded || enableCache === undefined) return;

    if (enableCache) {
      fetchCachedSlotAround({locations, specialtyId}).then(setSlot);
    } else {
      // @ts-expect-error TS2532: Object is possibly 'undefined'.
      const virtualSpecialtyIds = practices
        .flatMap(p => p.specialties.vals())
        .filter(s => s.isVirtual)
        .map(s => s.id)
        .distinct();

      const isVirtual = virtualSpecialtyIds.includes(specialtyId);

      // if virtual care, ignore closest location
      const closestLocationPracticeId =
        !isVirtual && locations.filter(l => l.specialtyIds.includes(specialtyId))[0]?.practiceId;

      const finalPracticeId = practiceId || closestLocationPracticeId || PracticeId.CARBON;

      const now = +moment();

      // @ts-expect-error TS2532, TS2684, TS2532: Object is possibly 'undefined'.,  The 'this' context of type 'RootStatePractice[] | undefined' is not assignable to method's 'this' of type 'RootStatePractice[]'.,  Object is possibly 'undefined'.
      const specialty = practices.findById(finalPracticeId).specialties[specialtyId] || {};

      const {
        defaultNewPatientApptDuration: duration = 10,
        apptLeadTime = 0,
      }: {defaultNewPatientApptDuration?: number; apptLeadTime?: number} = specialty;

      const from = (apptLeadTime * millisPerMinute || 0) < 0 ? now : now + apptLeadTime; // check if from is in past
      const to = +moment(now).add(daysCount, "days").endOf("day");

      loadAvailableSlots({
        practiceId: finalPracticeId,
        from,
        to,
        duration,
        specialtyId,
        timezone: laTimezone,
      }).then(slots => {
        setSlot(slots?.[0]?.[0] || {});
      });
    }
  }, [pageLoaded, enableCache]);

  return slot || null;
};

export type SoonestSlotTimeByLocationAndSpecialty = {
  [locationId: string]: {
    [specialtyId: string]: number;
  };
};

export const useGetSoonestTimeByLocationsAndSpecialtyIds = ({
  locations,
  specialtyIds,
  daysCount = 21,
  specialtyCount = 1, // if specialtyIds is not set
  locationCount = 3, // if locations are not set, closest locations count
}: {
  locations?: RootStateLocation[];
  specialtyIds?: SpecialtyId[];
  daysCount?: number;
  specialtyCount?: number;
  locationCount?: number;
}): SoonestSlotTimeByLocationAndSpecialty => {
  const {pageLoaded, locations: allLocations} = useTypedSelector(({config}) => ({...config}));
  const selectedRegion = useTypedSelector(selectSelectedRegion);
  const router = useRouter();
  const [slotLocationWithTime, setSlotLocationWithTime] =
    useState<SoonestSlotTimeByLocationAndSpecialty>({});

  const enableCache = useFeatureFlag<boolean>(FeatureFlag.GROWTH_CACHED_SLOTS_ENABLED);

  useEffect(() => {
    if (!pageLoaded || enableCache === undefined) return;

    const now = +moment();
    (locations || allLocations.slice(0, locationCount))
      .map(l => {
        const sortedSpecialties = l.specialties.vals().sortBy("sortIndex").slice(0, specialtyCount);
        return (specialtyIds || sortedSpecialties.map(s => s.id)) // if specialIds param is null, try with first specialtyId
          .map(sId => {
            const {defaultNewPatientApptDuration: duration = 10, apptLeadTime = 0} =
              l.specialties[sId] || {};

            const from = (apptLeadTime * millisPerMinute || 0) < 0 ? now : now + apptLeadTime; // check if from is in past

            return loadAvailableSlots({
              practiceId: l.practiceId,
              locationId: l.id,
              from,
              to: +moment(now).add(daysCount, "days").endOf("day"),
              duration,
              specialtyId: sId,
              timezone: l.timezone || laTimezone,
              enableCache,
            }).then(slotTimes => ({
              lId: l.id,
              sId,
              slotTime: getSoonestSlotFromApptSlotBlock(slotTimes),
            }));
          })
          .sequence();
      })
      .sequence()
      .then(locationsAndSlot => {
        setSlotLocationWithTime(
          locationsAndSlot
            .flat()
            .groupBy("lId")
            .mapValues(slots => slots.groupBy("sId").mapValues(slots => slots[0].slotTime)),
        );
      });
  }, [pageLoaded, router.asPath, selectedRegion, enableCache]);

  return slotLocationWithTime;
};

/* get first location with soonest specialty in location list
eg (assuming sf's urgentcare slot is sooner):
({locations: [berkeley, sf],specialtyId: UrgentCareId}) => {locationId: [sfLocationId]: time: 1666666} */
export const getSortedSoonestLocationWithAvailableSlotBySpecialty = ({
  locationsWithSlots,
  specialtyId,
}: {
  locationsWithSlots: SoonestSlotTimeByLocationAndSpecialty;
  specialtyId?: SpecialtyId;
}): {
  locationId: string;
  time?: number;
} => {
  if (locationsWithSlots.isOEmpty()) {
    // @ts-expect-error TS2322: Type 'null' is not assignable to type 'string'.
    return {locationId: null};
  }
  return (
    locationsWithSlots
      .toArray()
      // @ts-expect-error TS2571: Object is of type 'unknown'.
      .sortBy(l => l[1][specialtyId])
      // @ts-expect-error TS2571: Object is of type 'unknown'.
      .map(l => ({locationId: l[0], time: l[1][specialtyId]}))[0]
  );
};

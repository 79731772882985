import {RootStateLocation} from "../store/types";

export const filterLocationsBySpecialtyId = (
  locations: RootStateLocation[],
  specialtyId: string,
  limit = 3,
): RootStateLocation[] =>
  locations.filter(l => l.specialtyIds.includes(specialtyId)).slice(0, limit);

export const doesLocationHaveASpecialtyId =
  (specialtyIds: string[]) =>
  (location: {specialtyIds: string[]}): boolean =>
    location.specialtyIds.some(specialtyId => specialtyIds.includes(specialtyId));
